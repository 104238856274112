export const SET_ADD_FIELD_NURSE_STATUS = 'SET_ADD_FIELD_NURSE_STATUS';
export const SET_ADD_AGENCY_STATUS = 'SET_ADD_AGENCY_STATUS';
export const SET_CREATED_AGENCY_NAME = 'SET_CREATED_AGENCY_NAME';
export const SET_ADD_FIELD_NURSE_DIRECTLY = 'SET_ADD_FIELD_NURSE_DIRECTLY';

export const setAddFieldNurseStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_ADD_FIELD_NURSE_STATUS,
    payload,
  });
};

export const setAddAgencyStatus = (payload) => (dispatch) => {
  dispatch({
    type: SET_ADD_AGENCY_STATUS,
    payload,
  });
};

export const setCreatedAgencyName = (payload) => (dispatch) => {
  dispatch({
    type: SET_CREATED_AGENCY_NAME,
    payload,
  });
};

export const setAddFieldNurseDirectly = (payload) => (dispatch) => {
  dispatch({
    type: SET_ADD_FIELD_NURSE_DIRECTLY,
    payload,
  });
};
