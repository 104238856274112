export const SET_SECTION = 'SET_SECTION';
export const SET_ERROR = 'SET_ERROR';
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const SET_COGNITO_AUTH_USER = 'SET_COGNITO_AUTH_USER';
export const UPDATE_MODAL_CONTENT_TYPE = 'UPDATE_MODAL_CONTENT_TYPE';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const LOGIN = 'LOGIN';
export const SET_OPTIONS = 'SET_OPTIONS';

export const setSection = (payload) => (dispatch) => {
  dispatch({
    type: SET_SECTION,
    payload,
  });
};

export const setError = (payload) => (dispatch) => {
  dispatch({
    type: SET_ERROR,
    payload,
  });
};

export const setSuccessMessage = (payload) => (dispatch) => {
  dispatch({
    type: SET_SUCCESS_MESSAGE,
    payload,
  });
};

export const setUser = (payload) => (dispatch) => {
  dispatch({
    type: SET_USER,
    payload,
  });
};

export const updateModalContentType = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_MODAL_CONTENT_TYPE,
    payload,
  });
};

export const closeModal = (payload) => (dispatch) => {
  dispatch({
    type: CLOSE_MODAL,
    payload,
  });
};

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};

export const login = () => (dispatch) => {
  dispatch({
    type: LOGIN,
  });
};

export const setCognitoAuthUser = (payload) => (dispatch) => {
  dispatch({
    type: SET_COGNITO_AUTH_USER,
    payload,
  });
};

export const setOptions = (payload) => (dispatch) => {
  dispatch({
    type: SET_OPTIONS,
    payload,
  });
};
