import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import { solidColors } from '../utils/styleHelpers/Colors';
import { PrivateRoute } from '../components/PrivateRoute';
import { Spinner } from '../components/Spinner';
import { HomeRouter } from './Home';
import useHelpShift from '../utils/customHooks/useHelpShift';

const Login = lazy(() => import('../pages/Login/LoginContainer'));
const VerifyLogin = lazy(() => import('../pages/VerifyLogin/VerifyLogin'));
const ResetPassword = lazy(() => import('../pages/ResetPassword/ResetPassword'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword/ForgotPassword'));

export const RouterConfig = () => {
  useHelpShift();
  return (
    <Suspense fallback={<Spinner size={48} color={solidColors.primary} />}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/verify" component={VerifyLogin} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/forgotPassword" component={ForgotPassword} />
        <PrivateRoute path="/" component={HomeRouter} />
      </Switch>
    </Suspense>
  );
};
