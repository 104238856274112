import { css } from '@emotion/core';
import { fontFamilies } from './Typography';
import iconStyles from './Icons';
import elementStyles from './ElementFonts';
import formStyles from './FormStyles';
import { colors } from './Colors';

const globalStyles = css`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border-collapse: collapse;
  }

  html,
  body,
  #root {
    min-height: 100vh;
    overflow: inherit;
    font-family: ${fontFamilies.lato};
    letter-spacing: 0;
  }

  .app-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: ${colors.background.secondary};
  }

  ${formStyles};
  ${elementStyles};
  ${iconStyles};
`;

export default globalStyles;
