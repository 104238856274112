import {
  SET_CREATED_EPISODE_ID,
  SET_CREATED_PATIENT,
  SET_INFERRED_PATIENT,
  SET_PATIENT_SEARCH,
} from '../actions/PatientActions';

const initialState = {
  patientSearch: '',
  createdEpisodeId: '',
  createdPatient: {
    id: '',
    name: '',
  },
  inferredPatient: {}
};

export default function patientReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CREATED_EPISODE_ID:
      return {
        ...state,
        createdEpisodeId: action.payload,
      };
    case SET_CREATED_PATIENT:
      return {
        ...state,
        createdPatient: {
          id: action.payload.id,
          name: action.payload.name,
          ...action.payload
        },
      };
    case SET_INFERRED_PATIENT:
      return {
        ...state,
        inferredPatient: action.payload,
      };
    case SET_PATIENT_SEARCH:
      return {
        ...state,
        patientSearch: action.payload,
      };
    default:
      return state;
  }
}
