import {
  SET_CARE_PLAN,
  SET_CARE_PLAN_EPISODE_ID,
  SET_CARE_PLAN_CATEGORIES,
  SET_CARE_PLAN_CATEGORY_GOALS,
  SET_CARE_PLAN_INTERVENTION_CATEGORIES,
  SET_CARE_PLAN_CATEGORY_INTERVENTIONS,
  SET_CARE_PLAN_GOAL_INTERVENTIONS,
  UPDATE_CARE_PLAN_INTERVENTIONS,
  UPDATE_CARE_PLAN_STEPS,
  UPDATE_CARE_PLAN_RELEVANCE_ITEMS,
  SET_RELEVANT_OPTIONS,
  SET_NON_RELEVANT_OPTIONS,
  SET_VISIT_INTERVENTIONS,
  SET_MAPPABLE_INTERVENTIONS,
  SET_DRAFT_VISIT_INTERVENTIONS,
  SET_CARE_PLAN_PATIENT_DETAILS,
  SET_CARE_PLAN_CHECKLISTS,
  UPDATE_CARE_PLAN_CHECKLISTS_POSITIONS,
  UPDATE_CARE_PLAN_CHECKLISTS_RELEVANCE_UPDATES,
  RESET_CARE_PLAN_CHECKLIST_UPDATES,
  SET_ROW_EXPANDED,
  SET_CARE_PLAN_EXPANDED,
  SET_SAVED_CHANGES,
  SET_GOALS_CARRY,
  RESET_CARE_PLAN,
} from '../actions/CarePlanActions';

const initialState = {
  patient: {},
  carePlan: {},
  categories: [],
  categoryGoals: {},
  interventionCategories: [],
  categoryInterventions: {},
  goalInterventions: {},
  goalsCarry: [],
  updatedCarePlan: {},
  relevantOptions: [],
  carePlanChecklists: {},
  checklistExpanded: {},
  carePlanExpanded: {},
  updatedCarePlanChecklists: {},
  nonRelevantOptions: [],
  mappableInterventions: [],
  visitInterventions: {},
  savedChanges: {
    checklist: {},
    unpublishedCarePlan: {},
    publishedCarePlan: {},
    mapping: {},
  },
};

export default function carePlanReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CARE_PLAN:
      return {
        ...state,
        carePlan: action.payload,
      };
    case SET_CARE_PLAN_EPISODE_ID:
      return {
        ...state,
        carePlan: { ...state.carePlan, episode_id: action.payload },
      };
    case SET_CARE_PLAN_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_CARE_PLAN_INTERVENTION_CATEGORIES:
      return {
        ...state,
        interventionCategories: action.payload,
      };
    case SET_CARE_PLAN_CATEGORY_GOALS:
      return {
        ...state,
        categoryGoals: { ...state.categoryGoals, [action.payload.categoryId]: action.payload.goals },
      };
    case SET_CARE_PLAN_CATEGORY_INTERVENTIONS:
      return {
        ...state,
        categoryInterventions: {
          ...state.categoryInterventions,
          [action.payload.categoryId]: action.payload.interventions,
        },
      };
    case SET_CARE_PLAN_GOAL_INTERVENTIONS:
      return {
        ...state,
        goalInterventions: {
          ...state.goalInterventions,
          [action.payload.goalId]: action.payload.interventions,
        },
      };
    case UPDATE_CARE_PLAN_INTERVENTIONS:
      return {
        ...state,
        updatedCarePlan: action.payload,
      };
    case UPDATE_CARE_PLAN_STEPS:
      return {
        ...state,
        updatedCarePlan: { ...state.updatedCarePlan, steps: action.payload },
      };
    case UPDATE_CARE_PLAN_RELEVANCE_ITEMS:
      return {
        ...state,
        updatedCarePlan: { ...state.updatedCarePlan, relevanceUpdateItems: action.payload },
      };
    case SET_RELEVANT_OPTIONS:
      return {
        ...state,
        relevantOptions: action.payload,
      };
    case SET_NON_RELEVANT_OPTIONS:
      return {
        ...state,
        nonRelevantOptions: action.payload,
      };
    case SET_MAPPABLE_INTERVENTIONS:
      return {
        ...state,
        mappableInterventions: action.payload,
      };
    case SET_VISIT_INTERVENTIONS:
      return {
        ...state,
        visitInterventions: action.payload,
      };
    case SET_DRAFT_VISIT_INTERVENTIONS:
      return {
        ...state,
        draftVisitIntervention: action.payload,
      };
    case SET_CARE_PLAN_PATIENT_DETAILS:
      return {
        ...state,
        patient: action.payload,
      };
    case SET_CARE_PLAN_CHECKLISTS:
      return {
        ...state,
        carePlanChecklists: action.payload,
      };
    case UPDATE_CARE_PLAN_CHECKLISTS_POSITIONS:
      return {
        ...state,
        updatedCarePlanChecklists: {
          ...state.updatedCarePlanChecklists,
          positionUpdates: action.payload,
        },
      };
    case UPDATE_CARE_PLAN_CHECKLISTS_RELEVANCE_UPDATES:
      return {
        ...state,
        updatedCarePlanChecklists: {
          ...state.updatedCarePlanChecklists,
          relevanceUpdates: action.payload,
        },
      };
    case SET_ROW_EXPANDED:
      return {
        ...state,
        checklistExpanded: action.payload,
      };
    case SET_CARE_PLAN_EXPANDED:
      return {
        ...state,
        carePlanExpanded: action.payload,
      };
    case SET_SAVED_CHANGES:
      return {
        ...state,
        savedChanges: {
          ...state.savedChanges,
          [action.payload.type]: action.payload.data,
        },
      };
    case RESET_CARE_PLAN_CHECKLIST_UPDATES:
      return {
        ...state,
        updatedCarePlanChecklists: {},
      };
    case SET_GOALS_CARRY:
      return {
        ...state,
        goalsCarry: action.payload,
      };
    case RESET_CARE_PLAN:
      return initialState;

    default:
      return state;
  }
}
