import { SET_VISIT_NOTE_FIELDS, SET_VISIT_NOTE_RESPONSE_DATA } from '../actions/VisitNote';

const initialState = {
  visitNoteFields: {},
};

export default function visitNoteReducer(state = initialState, action) {
  const obj = {};

  switch (action.type) {
    case SET_VISIT_NOTE_FIELDS:
      obj[action.payload.id] = action.payload.value;
      return {
        ...state,
        visitNoteFields: { ...state.visitNoteFields, ...obj },
      };
    case SET_VISIT_NOTE_RESPONSE_DATA:
      return {
        ...state,
        visitNoteFields: { ...state.visitNoteFields, ...action.payload },
      };
    default:
      return state;
  }
}
