export const SET_CARE_PLAN = 'SET_CARE_PLAN';
export const SET_CARE_PLAN_CATEGORIES = 'SET_CARE_PLAN_CATEGORIES';
export const SET_CARE_PLAN_CATEGORY_GOALS = 'SET_CARE_PLAN_CATEGORY_GOALS';
export const SET_CARE_PLAN_INTERVENTION_CATEGORIES = 'SET_CARE_PLAN_INTERVENTION_CATEGORIES';
export const SET_CARE_PLAN_CATEGORY_INTERVENTIONS = 'SET_CARE_PLAN_CATEGORY_INTERVENTIONS';
export const SET_CARE_PLAN_GOAL_INTERVENTIONS = 'SET_CARE_PLAN_GOAL_INTERVENTIONS';
export const SET_CARE_PLAN_EPISODE_ID = 'SET_CARE_PLAN_EPISODE_ID';
export const UPDATE_CARE_PLAN_INTERVENTIONS = 'UPDATE_CARE_PLAN';
export const UPDATE_CARE_PLAN_STEPS = 'UPDATE_CARE_PLAN_STEPS';
export const UPDATE_CARE_PLAN_RELEVANCE_ITEMS = 'UPDATE_CARE_PLAN_RELEVANCE_ITEMS';
export const SET_RELEVANT_OPTIONS = 'SET_RELEVANT_OPTIONS';
export const SET_NON_RELEVANT_OPTIONS = 'SET_NON_RELEVANT_OPTIONS';
export const SET_VISIT_INTERVENTIONS = 'SET_VISIT_INTERVENTIONS';
export const SET_MAPPABLE_INTERVENTIONS = 'SET_MAPPABLE_INTERVENTIONS';
export const SET_DRAFT_VISIT_INTERVENTIONS = 'SET_DRAFT_VISIT_INTERVENTIONS';
export const SET_CARE_PLAN_PATIENT_DETAILS = 'SET_CARE_PLAN_PATIENT_DETAILS';
export const SET_CARE_PLAN_CHECKLISTS = 'SET_CARE_PLAN_CHECKLISTS';
export const UPDATE_CARE_PLAN_CHECKLISTS_POSITIONS = 'UPDATE_CARE_PLAN_CHECKLISTS_POSITIONS';
export const UPDATE_CARE_PLAN_CHECKLISTS_RELEVANCE_UPDATES = 'UPDATE_CARE_PLAN_CHECKLISTS_RELEVANCE_UPDATES';
export const SET_ROW_EXPANDED = 'SET_ROW_EXPANDED';
export const SET_SAVED_CHANGES = 'SET_SAVED_CHANGES';
export const RESET_CARE_PLAN_CHECKLIST_UPDATES = 'RESET_CARE_PLAN_CHECKLIST_UPDATES';
export const RESET_CARE_PLAN = 'RESET_CARE_PLAN';
export const SET_CARE_PLAN_EXPANDED = 'SET_CARE_PLAN_EXPANDED';
export const SET_GOALS_CARRY = 'SET_GOALS_CARRY';

export const setCarePlan = (payload) => (dispatch) => {
  dispatch({
    type: SET_CARE_PLAN,
    payload,
  });
};

export const setGoalsCarry = (goalsCarry) => ({
  type: SET_GOALS_CARRY,
  payload: goalsCarry,
});

export const setCarePlanEpisodeId = (payload) => (dispatch) => {
  dispatch({
    type: SET_CARE_PLAN_EPISODE_ID,
    payload,
  });
};

export const setCarePlanCategories = (payload) => (dispatch) => {
  dispatch({
    type: SET_CARE_PLAN_CATEGORIES,
    payload,
  });
};

export const setCarePlanCategoryGoals = (payload) => (dispatch) => {
  dispatch({
    type: SET_CARE_PLAN_CATEGORY_GOALS,
    payload,
  });
};

export const setCarePlanInterventionCategories = (payload) => (dispatch) => {
  dispatch({
    type: SET_CARE_PLAN_INTERVENTION_CATEGORIES,
    payload,
  });
};

export const setCarePlanCategoryInterventions = (payload) => (dispatch) => {
  dispatch({
    type: SET_CARE_PLAN_CATEGORY_INTERVENTIONS,
    payload,
  });
};

export const setCarePlanGoalInterventions = (payload) => (dispatch) => {
  dispatch({
    type: SET_CARE_PLAN_GOAL_INTERVENTIONS,
    payload,
  });
};

export const updateCarePlanInterventions = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_CARE_PLAN_INTERVENTIONS,
    payload,
  });
};

export const updateCarePlanRelevanceItem = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_CARE_PLAN_RELEVANCE_ITEMS,
    payload,
  });
};

export const setRelevantOptions = (payload) => (dispatch) => {
  dispatch({
    type: SET_RELEVANT_OPTIONS,
    payload,
  });
};

export const setNonRelevantOptions = (payload) => (dispatch) => {
  dispatch({
    type: SET_NON_RELEVANT_OPTIONS,
    payload,
  });
};

export const setVisitInterventions = (payload) => (dispatch) => {
  dispatch({
    type: SET_VISIT_INTERVENTIONS,
    payload,
  });
};

export const setMappableIntervention = (payload) => (dispatch) => {
  dispatch({
    type: SET_MAPPABLE_INTERVENTIONS,
    payload,
  });
};

export const setDraftVisitInterventions = (payload) => (dispatch) => {
  dispatch({
    type: SET_DRAFT_VISIT_INTERVENTIONS,
    payload,
  });
};

export const setCarePlanPatientDetails = (payload) => (dispatch) => {
  dispatch({
    type: SET_CARE_PLAN_PATIENT_DETAILS,
    payload,
  });
};

export const setCarePlanChecklists = (payload) => (dispatch) => {
  dispatch({
    type: SET_CARE_PLAN_CHECKLISTS,
    payload,
  });
};

export const updateCarePlanChecklistPositions = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_CARE_PLAN_CHECKLISTS_POSITIONS,
    payload,
  });
};

export const updateCarePlanChecklistRelevanceUpdates = (payload) => (dispatch) => {
  dispatch({
    type: UPDATE_CARE_PLAN_CHECKLISTS_RELEVANCE_UPDATES,
    payload,
  });
};

export const resetCarePlanChecklistUpdates = (payload) => (dispatch) => {
  dispatch({
    type: RESET_CARE_PLAN_CHECKLIST_UPDATES,
    payload,
  });
};

export const setRowExpanded = (payload) => (dispatch) => {
  dispatch({
    type: SET_ROW_EXPANDED,
    payload,
  });
};

export const setCarePlanExpanded = (payload) => (dispatch) => {
  dispatch({
    type: SET_CARE_PLAN_EXPANDED,
    payload,
  });
};

export const setSavedChanges = (payload) => (dispatch) => {
  dispatch({
    type: SET_SAVED_CHANGES,
    payload,
  });
};

export const resetCarePlan = (payload) => (dispatch) => {
  dispatch({
    type: RESET_CARE_PLAN,
    payload,
  });
};
