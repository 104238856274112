import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { Global } from '@emotion/core';

import globalStyles from './utils/styleHelpers/GlobalStyles';
import { client } from './services/appsync-client';
import { RouterConfig } from './routes';
import store from './store';

function App() {
  return (
    <Provider store={store}>
      <Global styles={globalStyles} />
      <ApolloProvider client={client}>
        <div className="app-container">
          <BrowserRouter>
            <RouterConfig />
          </BrowserRouter>
        </div>
      </ApolloProvider>
    </Provider>
  );
}

export default App;
