export const SET_CREATED_EPISODE_ID = 'SET_CREATED_EPISODE_ID';
export const SET_CREATED_PATIENT = 'SET_CREATED_PATIENT';
export const SET_INFERRED_PATIENT = 'SET_INFERRED_PATIENT';
export const SET_PATIENT_SEARCH = 'SET_PATIENT_SEARCH';

export const setCreatedEpisodeId = (payload) => (dispatch) => {
  dispatch({
    type: SET_CREATED_EPISODE_ID,
    payload,
  });
};

export const setCreatedPatient = (payload) => (dispatch) => {
  dispatch({
    type: SET_CREATED_PATIENT,
    payload,
  });
};

export const setInferredPatient = (payload) => (dispatch) => {
  dispatch({
    type: SET_INFERRED_PATIENT,
    payload,
  });
};

export const setPatientSearch = (payload) => (dispatch) => {
  dispatch({
    type: SET_PATIENT_SEARCH,
    payload,
  });
};
