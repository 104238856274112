import { css } from '@emotion/core';
import { solidColors } from './Colors';
import { fontSizes, fontWeights } from './Typography';

const elementStyles = css`
  font-weight: ${fontWeights.bold};

  h1 {
    ${fontSizes(1.5, 2.25)};
    color: ${solidColors.text};
  }

  h2 {
    ${fontSizes(1.0625, 2.25)};
    color: ${solidColors.text};
  }

  h3 {
    ${fontSizes(1, 2.25)};
    color: ${solidColors.text};
  }

  h4 {
    ${fontSizes(0.875, 2.25)};
    color: ${solidColors.text};
  }

  p1 {
    ${fontSizes(0.875, 2.25)};
    font-weight: ${fontWeights.regular};
    color: ${solidColors.text};
  }

  p2 {
    ${fontSizes(0.875, 2.25)};
    font-weight: ${fontWeights.regular};
    color: ${solidColors.text};
    opacity: 0.6;
  }

  t1 {
    ${fontSizes(0.75, 0.75)};
    font-weight: ${fontWeights.regular};
    color: ${solidColors.text};
  }

  h {
    ${fontSizes(0.75, 0.75)};
    color: ${solidColors.secondary};
  }

  a {
    ${fontSizes(0.75, 0.75)};
    color: ${solidColors.link};
  }
`;

export default elementStyles;
