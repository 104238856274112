export const USER_ROLE_TYPE = {
  SUPERADMIN: 'superadmin',
  AGENCY_ADMIN: 'agency_admin',
  FIELD_NURSE: 'field_nurse',
  PATRIUM_NURSE: 'patrium_nurse',
};

export const USER_GROUP_TYPE = {
  SUPERADMIN_COGNITO_GROUP: 'SuperAdmin',
  AGENCY_ADMINS_COGNITO_GROUP: 'AgencyAdmins',
  PATRIUM_NURSE_COGNITO_GROUP: 'PatriumNurses',
};

export const VISIT_STATUS = {
  SCHEDULED: 'Scheduled',
  RECORDING_SUBMITTED: 'RecordingSubmitted',
  TRANSCRIPTED: 'Transcripted',
  ASSIGNED: 'Assigned',
  PENDING_QA: 'PendingQA',
  IN_QA: 'InQA',
  CORRECTIONS_NEEDED: 'CorrectionsNeeded',
  FC_PENDING_REVIEW: 'FCPendingReview',
  FC_IN_REVIEW: 'FCInReview',
  FC_APPROVED: 'FCApproved',
  READY_FOR_DOCUMENTATION: 'ReadyForDocumentation',
};

export const VISIT_COMPLETION_STATUS = {
  MISSED: 'missed',
  COMPLETED: 'completed',
  SCHEDULED: 'scheduled',
};

export const VISIT_COMPLETION_STATUS_TEXT = {
  MISSED_TEXT: 'Visit Missed',
  COMPLETED_TEXT: 'Visit Completed',
  SCHEDULED_TEXT: 'Not Started',
};

export const VISIT_TYPE_TEXT = {
  SOC_TEXT: 'Start Of Care',
  FUC_TEXT: 'Follow Up Care',
};

export const VISIT_INFO = {
  MISSED: 'Visit Missed',
  WAITING: 'Waiting',
  IN_PROGRESS: 'In Progress',
  FOR_RECORDING: 'For Recording',
  TRANSCRIPTION: 'Transcription',
  FOR_PHOTOS: 'For Photos',
  UPLOADING_WENT_WRONG: 'Uploading went wrong',
  PHOTOS: 'Photos',
};

export const SECTIONS = {
  PATIENT_MANAGEMENT: 'patient-management',
  AGENCIES: 'agencies',
  FIELD_NURSES: 'field-nurses',
  PATRIUM_NURSES: 'patrium-nurses',
  ADMIN_PATRIUM_NURSES: 'admin-patrium-nurses',
  AGENCY_PATRIUM_NURSES: 'agency-patrium-nurses',
  REPORTS: 'reports',
  PATIENTS: 'patients',
  PATIENT_DETAIL: 'patient-detail',
  DASHBOARD: 'dashboard',
  DISCHARGED: 'discharged',
  ALL_USERS: 'all-users',
};

export const TABLES = {
  PATIENTS: 'Patients',
};

export const ERROR_MESSAGES = {
  USER_STORAGE: 'Error storing user details',
};

export const AUTH_USER_TOKEN_KEY = 'token';

export const initialFieldNurseState = {
  nurseFirstName: '',
  nurseLastName: '',
  nurseGender: null,
  nursePhone: '',
  nurseEmail: '',
};

export const errorInitialFieldNurseState = {
  nurseFirstName: false,
  nurseLastName: false,
  nurseGender: false,
  nursePhone: false,
  nurseEmail: false,
};

export const fieldNurseText = {
  messageLine1: 'All your field Clinicians will appear here. ',
  messageLine2: 'Start adding clinicians by clicking the below button.',
  searchPlaceholder: 'Search Field Clinicians',
};

export const errors = {
  nurseListError: 'Error getting nurse List.',
  nurseCountError: 'Error getting nurse count.',
  specializationError: 'Error getting specialization.',
  errorAddingNurse: 'Error adding nurse.',
  editError: 'Unable to save nurse details, please try again.',
  editNurseError: 'Error updating nurse.',
};

export const success = {
  fieldNurseSuccessfulySaved: 'Field Clinician Successfuly Saved.',
};

export const agenciesText = {
  add: 'Add',
};

export const medications = {
  MEDICATION_MODAL_HEADER: 'Medication Profile of',
  MEDICATION_DELETE_CONFIRMATION_1: 'Are you sure you want to',
  MEDICATION_DELETE_CONFIRMATION_2: 'medication?',
  ADD_A_MEDICINE: 'Add a Medicine',
  MEDICATION: 'Medication',
  DOSE: 'Dose',
  STRENGTH: 'Strength',
  ROUTE: 'Route',
  FREQUENCY: 'Frequency',
  DURATION: 'Duration',
  CLASS: 'Class',
  MEDICATION_PURPOSE: 'Purpose Of Medication',
  SPECIAL_INSTRUCTIONS: 'Special Instructions',
  MEDICATION_NAME_LABEL: 'Medication Name',
  MEDICATION_ROUTE_LABEL: 'Route',
  MEDICATION_DOSE_LABEL: 'Dose',
  MEDICATION_STRENGTH_LABEL: 'Strength',
  MEDICATION_FREQUENCY_LABEL: 'Frequency',
  MEDICATION_CLASS_LABEL: 'Class',
  MEDICATION_PURPOSE_LABEL: 'Purpose of Medication',
  MEDICATION_DURATION_LABEL: 'Duration',
  MEDICATION_INSTRUCTION_LABEL: 'Special Instructions',
  MEDICATION_NAME_PLACEHOLDER: 'Type medication name',
  MEDICATION_DOSE_PLACEHOLDER: 'Type dose',
  MEDICATION_STRENGTH_PLACEHOLDER: 'Type strength',
  MEDICATION_ROUTE_PLACEHOLDER: 'Type route name',
  MEDICATION_FREQUENCY_PLACEHOLDER: 'Type frequency',
  MEDICATION_CLASS_PLACEHOLDER: 'Enter class',
  MEDICATION_PURPOSE_PLACEHOLDER: 'Type purpose',
  MEDICATION_INSTRUCTIONS_PLACEHOLDER: 'Write instructions here..',
  START_DATE_PLACEHOLDER: 'Start Date',
  END_DATE_PLACEHOLDER: 'End Date',
  DAYS_PLACEHOLDER: '0',
  DISCONTINUED_ON_TEXT: 'Discontinued by PN on',
  MD_DISCONTINUED_ON: 'MD Discontinued On',
  FC_DISCONTINUED_ON: 'FC Discontinued On',
  PATIENT_DISCONTINUED_ON: 'Patient Discontinued On',
  PN_DISCONTINUED_ON: 'PN Discontinued On',
  REVIEW_MEDICATION: {
    QA_REVIEW_SUB_HEADING:
      'Select the medications from the list, then submit it. Submitting indicates that the drug, dose, route, and frequency were entered correctly.',
    APPROVED_SUB_HEADING: 'Approved medications list',
    QA_REVIEW: 'in-qa-review',
    APPROVED: 'approved',
    REVIEW_TAB_NAME: 'QA review',
    ADD_MEDICATION_TAB_NAME: 'Add medications',
    APPROVED_TAB_NAME: 'Approved Medications',
  },
};

export const MEDICATION_TYPE = {
  NEW: 'New',
  CHANGED: 'Changed',
  ONGOING: 'Ongoing',
};

export const MEDICATION_STATUS = {
  DISCONTINUED: 'discontinued',
};

export const datetime = {
  DAYS: 'Days',
  WEEK: 'Week',
  WEEKS: 'Weeks',
  MONTHS: 'Months',
};

export const TEXT = {
  SAVE: 'Save',
  CANCEL: 'Cancel',
  FOR: 'For',
  TO: 'To',
  YES: 'Yes',
  SUBMIT: 'Submit',
  DELETE: 'delete',
  REMOVE: 'remove',
};

export const ERRORS = {
  MEDICATION_ORDERS: {
    DELETE_MEDICATION: 'Error Deleting Medication',
    DISCONTINUE_MEDICATION: 'Error Discontinuing Medication',
  },
  MEDICATION_REVIEW: {
    REVIEW: 'Error updating reviewed medications',
  },
};

export const ACTION_BUTTON_TEXT = {
  SAVE: 'Save',
  UPDATE: 'Update',
  CANCEL: 'Cancel',
};

export const PAGE_SIZE = 10;

export const RESEND_CODE_TIME = 60;

export const DATE_TIME_FORMATS = {
  DEFAULT_DATE_TIME: 'MM/DD/YYYY hh:mm A',
  DEFAULT_DATE: 'MM/DD/YYYY',
  DATE_WITH_SEPARATOR: 'MM-DD-YYYY',
  DATE_TIME_WITH_SEPARATOR: 'YYYY-MM-DD hh:mm:ss',
  DEFAULT_TIME: 'hh:mm A',
  DEFAULT_FULL_DATE: 'MMM DD, YYYY',
  DEFAULT_FULL_MONTH_DATE: 'MMMM DD, YYYY',
  AWS_DEFAULT_DATE: 'YYYY-MM-DD',
  CALENDER_DEFAULT_DATE: 'MM/dd/yyyy',
  CALENDER_DEFAULT_DATE_TIME: 'MM/dd/yyyy hh:mm a',
  CALENDER_FULL_MONTH_DATE: 'MMMM dd, yyyy',
  AWS_DEFAULT_DATE_TIME: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
};

export const DOCUMENT_TIMELINE_STATUS = {
  RECORDING_RECEIVED: 'Recording Received',
  DOCUMENTATION_STARTED: 'Documentation Started',
  SUBMITTED_TO_QA: 'Documentation Submitted for QA',
  QA_REVIEW_STARTED: 'QA Review Started',
  CORRECTIONS_FROM_QA: 'QA Review Finished and Added Corrections',
  PICKED_FOR_CORRECTION: 'Picked Up for Correction',
  CORRECTED_AND_SENT: 'Corrections Done and Submitted for QA Review',
  SUBMITTED_TO_FC: 'QA Review Finished and Submitted to FC',
  DOCUMENTATION_STARTED_BY_FC: 'Documentation Started by FC',
  CORRECTIONS_FROM_FC: 'Received Corrections From FC',
  APPROVED: 'Approved By FC',
};

export const APOLLO_FETCH_POLICY = {
  CACHE_NETWORK: 'cache-and-network',
};

export const EDITOR_DEBOUNCE_TIME = 5000;
export const EDITOR_INTERVAL_TIME = 60000;

export const REACT_POPPER = {
  STRATEGY: {
    FIXED: 'fixed',
    ABSOLUTE: 'absolute',
  },
  PLACEMENT: {
    TOP: 'top',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    BOTTOM_END: 'bottom-end',
    LEFT: 'left',
    LEFT_START: 'left-start',
  },
};

export const CLINICIAN_TASK_STATUS = {
  NOT_STARTED: 'NotStarted',
  IN_PROGRESS: 'InProgress',
  MAX_POTENTIAL: 'MaxPotential',
  COMPLETE: 'Completed',
};

export const VISIT_NOTE_PROGRESS_TYPE = {
  STEP_BASED: 'stepBased',
  AVERAGE: 'average',
};

export const CARE_PLAN_HEADING = 'Update Care Plan';

export const OPTION_TYPE = {
  RELEVANT: 'Relevant',
  NON_RELEVANT: 'NonRelevant',
  ETHNICITY: 'Ethnicity',
  PAYMENT_SOURCE: 'PaymentSource',
  INPATIENT_FACILITY: 'InpatientFacility',
  ACTIVE_DIAGNOSIS: 'ActiveDiagnosis',
  ADVANCED_DIRECTIVES: 'AdvanceDirectives',
};

export const DEFAULT_FIELD_PLACEHOLDERS = {
  TEXT: '',
};

export const FIELD_TYPES = {
  TEXT: 'text',
  TEXT_AREA: 'textarea',
  SELECT: 'select',
  DATE: 'date',
  TIME: 'time',
  DATETIME: 'datetime',
  NUMBER: 'number',
  CHECKBOX: 'checkbox',
  STATUS_SELECT: 'statusPickList',
  PROGRESS: 'progress',
};

export const DOCUMENT_TYPE = {
  VISIT_RECORDING: 'VisitRecording',
};

export const AUDIO_PLAYER_ERROR_MESSAGE = {
  MEDIA_ERR_ABORTED: 'Audio fetching aborted by the user',
  MEDIA_ERR_NETWORK: 'Error occurred due to unstable internet',
  MEDIA_ERR_DECODE: 'Error decoding the audio file',
  MEDIA_ERR_SRC_NOT_SUPPORTED: 'Unsupported Media Type',
  AUDIO: 'There is some issue with the recording, please try again after some time.',
  BROWSER: 'Your browser does not support the audio element. Please update your browser.',
  OFFLINE: 'You are offline, please check your internet before retrying.',
};

export const ANNOTATION_FILTERS = {
  PERSON: 'Person',
  DIAGNOSIS_VITAL: 'Diagnoses, Vitals, Symptoms, Medications',
  DATE_AMOUNT: 'Date , Amount',
};
