export const SET_VISIT_ID = 'SET_VISIT_ID';
export const SET_VISIT_DURATION = 'SET_VISIT_DURATION';
export const SET_AGENCY_TIMEZONE = 'SET_AGENCY_TIMEZONE';
export const SET_AUDIO_TIME = 'SET_AUDIO_TIME';
export const SET_NEW_COMMENT_DATA = 'SET_NEW_COMMENT_DATA';
export const SET_DISABLE_EDITOR = 'SET_DISABLE_EDITOR';
export const SET_REVIEW_MODE = 'SET_REVIEW_MODE';
export const SET_ANNOTATION_FILTER_VALUES = 'SET_ANNOTATION_FILTER_VALUES';
export const SET_INTERVENTION_SECTION_FILTER_VALUES = 'SET_INTERVENTION_SECTION_FILTER_VALUES';

export const setVisitId = (payload) => (dispatch) => {
  dispatch({
    type: SET_VISIT_ID,
    payload,
  });
};

export const setAgencyTimezone = (payload) => (dispatch) => {
  dispatch({
    type: SET_AGENCY_TIMEZONE,
    payload,
  });
};

export const setVisitDurationSeconds = (payload) => (dispatch) => {
  dispatch({
    type: SET_VISIT_DURATION,
    payload,
  });
};

export const setAudioTime = (payload) => (dispatch) => {
  dispatch({
    type: SET_AUDIO_TIME,
    payload,
  });
};

export const setNewCommentData = (payload) => (dispatch) => {
  dispatch({
    type: SET_NEW_COMMENT_DATA,
    payload,
  });
};

export const setDisableEditor = (payload) => (dispatch) => {
  dispatch({
    type: SET_DISABLE_EDITOR,
    payload,
  });
};

export const setReviewMode = (payload) => (dispatch) => {
  dispatch({
    type: SET_REVIEW_MODE,
    payload,
  });
};

export const setAnnotationFilterValues = (payload) => (dispatch) => {
  dispatch({
    type: SET_ANNOTATION_FILTER_VALUES,
    payload,
  });
};

export const setInterventionFilterValues = (payload) => (dispatch) => {
  dispatch({
    type: SET_INTERVENTION_SECTION_FILTER_VALUES,
    payload,
  });
};
