const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: process.env.REACT_APP_AUTH_FLOW_TYPE,
  },
  API: {
    aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_END_POINT,
    aws_appsync_region: process.env.REACT_APP_REGION,
  },
};

export default awsConfig;
