import {
  SET_SECTION,
  SET_ERROR,
  SET_USER,
  SET_OPTIONS,
  SET_SUCCESS_MESSAGE,
  SET_COGNITO_AUTH_USER,
  UPDATE_MODAL_CONTENT_TYPE,
  CLOSE_MODAL,
  LOGOUT,
  LOGIN,
} from '../actions/AppActions';

const initialState = {
  loggedOut: false,
  cognitoAuthUser: null,
  currentUser: {
    name: '',
    activeRole: '',
  },
  currentSection: {
    name: '',
  },
  error: {
    message: '',
  },
  success: {
    message: '',
  },
  modal: {
    contentType: '',
  },
  options: {},
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SECTION:
      return {
        ...state,
        currentSection: { ...state.currentSection, name: action.payload },
      };
    case SET_ERROR:
      return {
        ...state,
        error: { ...state.error, message: action.payload },
      };
    case SET_SUCCESS_MESSAGE:
      return {
        ...state,
        success: { ...state.success, message: action.payload },
      };
    case SET_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case SET_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.type]: action.payload.value,
        },
      };
    case UPDATE_MODAL_CONTENT_TYPE:
      return {
        ...state,
        modal: { ...state.modal, contentType: action.payload },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modal: { contentType: '' },
      };
    case LOGOUT:
      return {
        ...state,
        loggedOut: true,
      };
    case LOGIN:
      return {
        ...state,
        loggedOut: false,
      };
    case SET_COGNITO_AUTH_USER:
      return {
        ...state,
        cognitoAuthUser: action.payload,
      };
    default:
      return state;
  }
}
