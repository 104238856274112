import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AUTH_USER_TOKEN_KEY } from '../../shared/Constants';
import { validateToken } from '../../utils/methods/auth';
import { logout } from '../../store/actions/AppActions';
import store from '../../store';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = localStorage.getItem(AUTH_USER_TOKEN_KEY);
  const { isValidJwt, loggedUser, userGroups } = validateToken(user);

  useEffect(() => {
    const logoutHandler = (e) => {
      if (e && e.key === 'token' && e.oldValue && !e.newValue) {
        store.dispatch(logout());
      }
    };
    window.addEventListener('storage', logoutHandler);
    return () => window.removeEventListener('storage', logoutHandler);
  }, []);

  useEffect(() => {
    if (!user || !isValidJwt) {
      store.dispatch(logout());
    }
  }, [user, isValidJwt]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isValidJwt ? (
          <Component {...rest} userGroups={userGroups} loggedUser={loggedUser} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

PrivateRoute.defaultProps = {
  component: null,
  location: {
    pathname: '',
  },
};

export default PrivateRoute;
