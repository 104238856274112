/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import styles from './Spinner.styles';

function Spinner({ color, className, size }) {
  return (
    <div data-testid="loader" className="spinner-container" css={styles(size)}>
      <svg className={className} viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" stroke={color} strokeWidth="5" />
      </svg>
    </div>
  );
}

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

Spinner.defaultProps = {
  size: 20,
  color: 'white',
  className: 'spinner',
};

export default Spinner;
