import jwtDecode from 'jwt-decode';

export const validateToken = (token) => {
  if (!token) {
    return false;
  }

  try {
    const decodedJwt = jwtDecode(token);
    const isValidJwt = decodedJwt.exp >= Date.now() / 1000;
    const loggedUser = decodedJwt.username;
    let userGroups = [];

    if (decodedJwt['cognito:groups']) {
      userGroups = decodedJwt['cognito:groups'];
    }

    return { isValidJwt, loggedUser, userGroups };
  } catch (e) {
    return false;
  }
};
