import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createAppSyncLink } from 'aws-appsync';
import Amplify, { Auth } from 'aws-amplify';
import awsConfig from './aws-exports';

const url = awsConfig.API.aws_appsync_graphqlEndpoint;
const region = awsConfig.API.aws_appsync_region;
const cache = new InMemoryCache();

Amplify.configure(awsConfig);

const auth = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const logoutLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    localStorage.removeItem('token');
  }
});

const httpLink = ApolloLink.from([createAuthLink({ url, region, auth }), createHttpLink({ uri: url })]);

const awsLink = createAppSyncLink({
  url,
  region,
  auth,
});

export const client = new ApolloClient({
  link: logoutLink.concat(awsLink).concat(httpLink),
  cache,
});
