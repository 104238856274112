import { SET_RELEVANT_OPTIONS, SET_NON_RELEVANT_OPTIONS } from '../actions/CarePlanActions';

const initialState = {
  relevantOption: [],
  nonRelevantOptions: [],
};

export default function optionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_RELEVANT_OPTIONS:
      return {
        ...state,
        relevantOption: action.payload,
      };
    case SET_NON_RELEVANT_OPTIONS:
      return {
        ...state,
        nonRelevantOptions: action.payload,
      };
    default:
      return state;
  }
}
