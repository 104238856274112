import { css } from '@emotion/core';
import { baseColors } from './Colors';

const iconStyles = css`
  svg {
    cursor: pointer;
  }

  .delete-icon {
    g g {
      fill: ${baseColors.iconRed};
    }
  }
`;

export default iconStyles;
