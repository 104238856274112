import { ANNOTATION_FILTERS } from '../../shared/Constants';
import {
  SET_VISIT_ID,
  SET_VISIT_DURATION,
  SET_AGENCY_TIMEZONE,
  SET_AUDIO_TIME,
  SET_NEW_COMMENT_DATA,
  SET_DISABLE_EDITOR,
  SET_REVIEW_MODE,
  SET_ANNOTATION_FILTER_VALUES,
  SET_INTERVENTION_SECTION_FILTER_VALUES,
} from '../actions/DocumentationActions';

const initialState = {
  visitId: '',
  visitDuration: 0,
  time: 0,
  timezone: { offsetMinute: 0 },
  newCommentData: {},
  disableEditor: false,
  reviewMode: false,
  annotationFilterValues: Object.values(ANNOTATION_FILTERS),
  interventionSectionFilterValues: [],
};

export default function documentationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_VISIT_ID:
      return {
        ...state,
        visitId: action.payload,
      };
    case SET_AGENCY_TIMEZONE:
      return {
        ...state,
        timezone: { ...action.payload },
      };
    case SET_VISIT_DURATION:
      return {
        ...state,
        visitDuration: action.payload,
      };
    case SET_AUDIO_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case SET_NEW_COMMENT_DATA:
      return {
        ...state,
        newCommentData: action.payload,
      };
    case SET_DISABLE_EDITOR:
      return {
        ...state,
        disableEditor: action.payload,
      };
    case SET_REVIEW_MODE:
      return {
        ...state,
        reviewMode: action.payload,
      };
    case SET_ANNOTATION_FILTER_VALUES:
      return {
        ...state,
        annotationFilterValues: action.payload,
      };
    case SET_INTERVENTION_SECTION_FILTER_VALUES:
      return {
        ...state,
        interventionSectionFilterValues: action.payload,
      };
    default:
      return state;
  }
}
