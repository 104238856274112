import {
  SET_ADD_FIELD_NURSE_STATUS,
  SET_ADD_AGENCY_STATUS,
  SET_CREATED_AGENCY_NAME,
  SET_ADD_FIELD_NURSE_DIRECTLY,
} from '../actions/AgencyActions';

const initialState = {
  addFieldNurseStatus: false,
  addAgencyStatus: false,
  createdAgencyName: '',
  addFieldNurseDirectly: false,
};

export default function agencyReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ADD_FIELD_NURSE_STATUS:
      return {
        ...state,
        addAgencyStatus: false,
        addFieldNurseStatus: action.payload,
      };
    case SET_ADD_AGENCY_STATUS:
      return {
        ...state,
        addFieldNurseStatus: false,
        addAgencyStatus: action.payload,
      };
    case SET_CREATED_AGENCY_NAME:
      return {
        ...state,
        createdAgencyName: action.payload,
      };
    case SET_ADD_FIELD_NURSE_DIRECTLY:
      return {
        ...state,
        addFieldNurseDirectly: action.payload,
      };
    default:
      return state;
  }
}
