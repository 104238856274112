import { combineReducers } from 'redux';
import AppReducer from './reducers/AppReducer';
import AgencyReducer from './reducers/AgencyReducer';
import PatientReducer from './reducers/PatientReducer';
import PatriumNurseReducer from './reducers/PatriumNurseReducer';
import DocumentationReducer from './reducers/DocumentationReducer';
import carePlanReducer from './reducers/CarePlanReducer';
import optionsReducer from './reducers/OptionsReducer';
import visitNoteReducer from './reducers/VisitNote';

const appReducer = combineReducers({
  app: AppReducer,
  agency: AgencyReducer,
  patriumNurse: PatriumNurseReducer,
  patient: PatientReducer,
  documentation: DocumentationReducer,
  carePlan: carePlanReducer,
  relevanceOptions: optionsReducer,
  visitNote: visitNoteReducer,
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'LOGOUT') {
    newState = undefined;
  }
  return appReducer(newState, action);
};

export default rootReducer;
