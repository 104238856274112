export const transparentColors = {
  black: 'rgba(0, 0, 0, 0.5)',
  black2: 'rgba(0, 0, 0, 0.08)',
  gray3: 'rgba(214, 214, 214, 0.4)',
  gray4: 'rgba(127,	123,	144 ,0.2)',
  gray5: 'rgba(42,	39	,71, 0.4)',
  gray6: 'rgba(32, 28, 74, 0.05)',
  topaz: 'rgba(127, 123, 144, 0.2)',
  gray7: 'rgba(59, 59, 59, 0.2)',
  gray8: 'rgb(86, 90, 100, 0.5)',
  documentationStatusModal: 'rgb(86, 87, 91, 0.3)',
  shadow: {
    primary: 'rgba(0, 191, 211, 0.29)',
    secondary: 'rgba(113, 42, 162, 0.29)',
    patientDetailsCard: 'rgba(32, 28, 74, 0.13)',
    interventionCard: 'rgba(32, 28, 74, 0.08)',
    notificationBadge: 'rgba(188,187,187,0.5)',
    notificationBanner: 'rgba(32, 28, 74, 0.2)',
    dischargeModal: 'rgba(71, 70, 95, 0.28)',
    loginScreen: 'rgba(12, 12, 12, 0.28)',
    draggableItem: '#d7dfec',
  },
};

export const baseColors = {
  primary1: '#55d4e2',
  primary2: '#aaeaf0',
  primary3: '#d5f4f8',
  primary4: '#e4f5f7',
  secondary1: '#a075c0',
  secondary2: '#cfbadf',
  secondary3: '#e7ddef',
  secondary4: '#f2eefa',
  iconBlue: '#0d214a',
  iconPrimary: '#00bfd3',
  iconRed: '#be0f0f',
  progressGreen: '#4AB754',
  circularProgressGreen: '#56AD16',
};

export const tagColors = ['#F0E8D8', '#E8F0D8', '#D8F0D8', '#DAF0F0', '#D8D8F0', '#FAE1FA', '#FAE1E1', '#EBEDF0'];

export const solidColors = {
  black: '#000',
  white: '#fff',
  primary: '#00bfd3',
  secondary: '#7030a0',
  text: '#2f3238',
  placeholder: '#9c9fa7',
  link: '#00a3b7',
  modalHeadingText: '#30138e',
  discontinuedOnText: '#7a7e86',
  label: '#7a7e86',
  disabledText: '#7a7e86',
  searchIcon: '#2a2846',
  timeInput: '#797e87',
  errorText: '#BE0F0F',
  scroll: '#f5f5f5',
  thumb: '#e1e2e6',
  line: '#dcdee8',

  blue: '#346bd5',
  gray1: '#565a64',
  gray2: '#adabc4',
  gray3: '#757C89',
  gray4: '#7E8086',
  gray5: '#0D214A',
  gray6: '#D6DEE6',
  violet: '#30138e',
  violet4: '#7b40a7',
  lightGray: '#565A64',
  secondaryGray: '#ccc',
  red: '#FF0000',
  red2: '#BE0F0F',
  green: '#0c7124',
  green2: '#007312',
  yellow1: '#B67324',
  yellow2: '#B77210',
  sideMenuTimezone: '#1f1d3c',
  darkGreen: '#1D806A',
  darkYellow: '#7D5709',
  darkPink: '#A63782',
  lightGreen: '#098f62',
};

export const colors = {
  background: {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    primary: '#00bfd3',
    secondary: '#2a2846',
    success: '#d7edc7',
    error: '#f7d5d5',
    disabledButton: '#c8e0e3',
    newLabel: '#c8f4e5',
    changedLabel: '#f4decd',
    onlineStatus: '#d2f1bb',
    offlineStatus: '#dcdee7',
    interventionCountBadge: '#dcdee7',
    notificationIconBackground: '#f5f3fa',
    helpInfo: '#f2eefa',
    initials: {
      green: '#53a95a',
    },
    clinicianTaskStatusSelector: {
      notStarted: '#f8e9dd',
      completed: '#e1f7d0',
      maxPotential: '#e1f7d0',
      inProgress: '#d5f4f8',
    },

    gray: '#f3f3f3',
    gray1: '#f5f7fa',
    gray2: '#222222',
    gray3: '#f0f2f8',
    gray4: '#d9d9d9',
    gray5: '#F8F8F8',
    gray6: '#f9f9f9',
    gray7: '#edf0f5',
    gray8: '#e6eaf0',
    gray9: '#D8D8D8',
    blue: '#346bd5',
    blue2: '#eff7f9',
    blue3: '#f3feff',
    red: '#fde7e7',
    violet: '#e6eaee',
    violet3: '#323155',
    violet4: '#7b40a7',
    violet5: '#f2eefa',
    iconRed: '#be0f0f',
    lightGray: '#F9F9F9',
    lightBlue: '#f8feff',
    orange: '#de8d5c',
    lightGreen: '#D3F0E6',
    yellow3: '#ffe59e',
    pink: '#F8E4F2',
    lightRed: '#fdfafa',
  },

  border: {
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    primary: '#00bfd3',
    secondary: '#464372',
    success: '#b4d28d',
    error: '#cca3a3',
    popover: '#d0d7ef',
    searchBox: '#dcdee8',
    carePlanCard: '#e6ebf2',
    draggableItem: '#e6ebf2',
    droppable: '#959595',
    goalCard: '#8661a1',
    interventionCard: '#70b5ba',
    stepCard: '#8fba70',
    notRelevantCard: '#CCCED6',
    carePlanInput: '#b8c1ce',
    carePlanSideBar: '#d7dae2',
    verticalLineSeparator: '#dcdee7',

    blue: '#346bd5',
    blue2: '#0d91a8',
    gray: '#c7cfd8',
    gray2: '#e3e3eb',
    gray3: '#d6d6d6',
    gray4: '#f0f0f0',
    gray5: '#e0e0e0',
    gray7: '#d2d2d2',
    topaz: '#7F7B90',
    lightGray: '#c1c7cd',
    red: '#eed0d0',
    violet: '#201c4a',
    violet2: '#a075c0',
    violet4: '#7b40a7',
    formError: '#d75c5c',
    iconRed: '#be0f0f',
    gray6: '#6d6f74',
    lightBlue: '#00bed4',
    gray8: '#bebbbb',
    gray9: '#e7e7e7',
    lightPurple: '#ece3ff',
    gray10: '#D7D7D7',
    gray11: '#E5E5E9',
    gray12: '#b6b6b6',
    gray13: '#E7E7E9',
    gray14: '#ECECEC',
    gray15: '#B8C1CE',
    gray16: '#9C9FA7',
    gray17: '#f8f8f8',
    gray18: '#E8E8E8',
    gray19: '#bbbdc1',
  },

  shadow: {
    primary: '#00bfd3',
    iconRed: '#be0f0f',
  },
};
