export const fontSizes = (fontSize, lineSize) => {
  const lineHeight = lineSize || fontSize * 1.5;

  return `
    font-size: ${fontSize * 16}px;
    line-height: ${lineHeight * 16}px;
  `;
};

export const fontFamilies = {
  default: '"proxima-nova", Arial, sans-serif',
  lato: '"lato", sans-serif',
};

export const fontWeights = {
  thin: '300',
  regular: '400',
  bold: '600',
  superBold: '800',
};
