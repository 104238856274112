import React, { lazy } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute';

const Home = lazy(() => import('../pages/Home/Home'));
const Dashboard = lazy(() => import('../pages/Dashboard/Dashboard'));
const Documentation = lazy(() => import('../pages/ViewRecording/ViewRecording'));
const CarePlan = lazy(() => import('../pages/CarePlan/CarePlan'));

export const HomeRouter = () => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <PrivateRoute exact path="/carePlan/:patientId" component={CarePlan} />
      <PrivateRoute exact path="/viewRecording/:id" component={Documentation} />
      <PrivateRoute exact path="/patientDetails/:patientId" component={Dashboard} />
      <PrivateRoute path={url} component={Home} />
    </Switch>
  );
};
