export const ADD_REMOVE_AGENCY_STATUS = 'ADD_REMOVE_AGENCY_STATUS';
export const SET_SIDE_VIEW_VISIBILITY = 'SET_SIDE_VIEW_VISIBILITY';
export const SET_DOCUMENTATION_TAB = 'SET_DOCUMENTATION_TAB';
export const REFETCH_DOCUMENTATION_LIST = 'REFETCH_DOCUMENTATION_LIST';

export const setAddRemoveAgencyStatus = (payload) => (dispatch) => {
  dispatch({
    type: ADD_REMOVE_AGENCY_STATUS,
    payload,
  });
};

export const setSideViewVisibility = (payload) => (dispatch) => {
  dispatch({
    type: SET_SIDE_VIEW_VISIBILITY,
    payload,
  });
};

export const setDocumentationActiveTab = (payload) => (dispatch) => {
  dispatch({
    type: SET_DOCUMENTATION_TAB,
    payload,
  });
};

export const refetchDocumentationTab = (payload) => (dispatch) => {
  dispatch({
    type: REFETCH_DOCUMENTATION_LIST,
    payload,
  });
};
