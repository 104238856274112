/* eslint-disable no-unused-expressions */
/* eslint func-names: ["error", "never"] */

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useHelpShift = () => {
  const currentUser = useSelector((state) => state.app.currentUser);
  const PLATFORM_ID = process.env.REACT_APP_HELPSHIFT_PF_ID;
  const DOMAIN = process.env.REACT_APP_HELPSHIFT_DOMAIN;
  const LANGUAGE = 'en';

  useEffect(() => {
    if (process.env.REACT_APP_HELPSHIFT !== 'off') {
      const script = document.createElement('script');
      const body = document.getElementsByTagName('body')[0];
      script.id = 'helpshift';
      script.src = '//js.hsforms.net/forms/v2.js';
      body.appendChild(script);
      script.addEventListener('load', () => {
        (function () {
          window.helpshiftConfig = {
            platformId: PLATFORM_ID,
            domain: DOMAIN,
            language: LANGUAGE,
          };
        })();

        !(function (t, e) {
          if (typeof window.Helpshift !== 'function') {
            const n = function (...rest) {
              n.q.push(rest);
            };
            n.q = [];
            window.Helpshift = n;
            const a = t.getElementsByTagName('script')[0];
            if (t.getElementById(e)) return;
            const i = t.createElement('script');
            i.async = !0;
            i.id = e;
            i.src = 'https://webchat.helpshift.com/latest/webChat.js';
            const o = function () {
              window.Helpshift('init');
            };
            window.attachEvent ? i.attachEvent('onload', o) : i.addEventListener('load', o, !1);
            a.parentNode.insertBefore(i, a);
          } else {
            window.helpshiftConfig.userId = currentUser.userId;
            window.helpshiftConfig.userName = currentUser.name;
            window.helpshiftConfig.userAuthToken = localStorage.getItem('token');
            window.Helpshift('updateHelpshiftConfig');
          }
        })(document, 'hs-chat');
      });
    }
  }, [currentUser]);
};

export default useHelpShift;
