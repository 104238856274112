export const SET_VISIT_NOTE_FIELDS = 'SET_VISIT_NOTE_FIELDS';
export const SET_VISIT_NOTE_RESPONSE_DATA = 'SET_VISIT_NOTE_RESPONSE_DATA';

export const setVisitNoteField = (payload) => (dispatch) => {
  dispatch({
    type: SET_VISIT_NOTE_FIELDS,
    payload,
  });
};

export const setVisitNoteResponseData = (payload) => (dispatch) => {
  dispatch({
    type: SET_VISIT_NOTE_RESPONSE_DATA,
    payload,
  });
};
