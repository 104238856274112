import {
  ADD_REMOVE_AGENCY_STATUS,
  SET_SIDE_VIEW_VISIBILITY,
  SET_DOCUMENTATION_TAB,
  REFETCH_DOCUMENTATION_LIST,
} from '../actions/PatriumNurseActions';

const initialState = {
  addRemoveAgencyStatus: false,
  isSideViewVisible: true,
  activeDocumentationTab: 3,
  refetchDocumentationList: false,
};

export default function patriumNurseReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_REMOVE_AGENCY_STATUS:
      return {
        ...state,
        addRemoveAgencyStatus: action.payload,
      };
    case SET_SIDE_VIEW_VISIBILITY:
      return {
        ...state,
        isSideViewVisible: action.payload,
      };
    case SET_DOCUMENTATION_TAB:
      return {
        ...state,
        activeDocumentationTab: action.payload,
      };
    case REFETCH_DOCUMENTATION_LIST:
      return {
        ...state,
        refetchDocumentationList: action.payload,
      };
    default:
      return state;
  }
}
