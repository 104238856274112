import { css } from '@emotion/core';
import { solidColors, colors, transparentColors } from './Colors';
import { fontFamilies, fontSizes, fontWeights } from './Typography';

const elementStyles = css`
  input {
    ${fontSizes(0.875, 0.875)};
    font-family: ${fontFamilies.lato};
    font-weight: ${fontWeights.regular};
    border: none;
    border-bottom: 2px solid ${colors.border.gray3};
    background-color: ${colors.background.white};
    color: ${solidColors.gray4};
    padding: 0 0 9px 0;
    opacity: 0.8;
    outline: none;
  }

  input:valid {
    opacity: 1;
    color: ${solidColors.text};
  }

  input:placeholder {
    color: ${solidColors.placeholder};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  // Remove arrows from number input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  textarea {
    font-family: ${fontFamilies.lato};
  }

  label {
    ${fontSizes(0.875, 1)};
    font-family: ${fontFamilies.lato};
    font-weight: ${fontWeights.bold};
    color: ${solidColors.text};
    margin-bottom: 16px;
  }

  // Auth Forms
  .auth {
    input {
      padding: 10px 15px;
      border: 1px solid ${colors.border.lightGray};
      background-color: ${colors.background.white};
      border-radius: 7px;
      opacity: 0.3;
      color: ${solidColors.black};
      ${fontSizes(0.875, 1.0625)};
      font-weight: ${fontWeights.regular};
      min-width: 310px;
    }

    input:valid {
      opacity: 1;
      border: 1px solid ${transparentColors.gray3};
    }
  }

  // Form Styles
  form {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    .form-row {
      width: 100%;
      margin-bottom: 32px;
      display: flex;
      flex-direction: row;
    }

    .form-field {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-right: 30px;

      &:nth-last-of-type {
        margin-right: 0;
      }
    }

    .gender {
      flex-direction: column;

      label {
        margin-bottom: 16px;
      }
    }

    input {
      margin-top: 10px;
      margin-right: 20px;
    }
  }
`;
export default elementStyles;
